.slot {
  display: flex;
  place-content: center;
  overflow: hidden;
}

.hidden {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important; /* overriding vendor styles */
}
