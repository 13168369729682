/*
@contract listing-card-preview-group {
  columns: GridTemplateColumns
  gap: Gap
  li {
    width: Width
    height: Height
  }
}
*/
@layer molecules {
  .listingCardPreviewGroupSkeleton {
    width: 100%;
  }

  .container {
    --horizontal-scroll-arrows-top: -50px;
    --horizontal-scroll-list-gap: var(--space-5x);
    --horizontal-scroll-list-display: grid;
    --horizontal-scroll-gradient-width: 15px;
    --horizontal-scroll-arrows-height: auto;

    @media (--supports-mouse) {
      --horizontal-scroll-arrow-button-disabled-visibility: visible;
      --horizontal-scroll-arrows-justify-content: flex-end;
      --horizontal-scroll-arrows-top: -40px;
    }
  }

  .listingCardPreviewGroupUlVertical {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    position: relative;
    font-size: var(--font-size-body);
    justify-content: flex-start;
    gap: var(--space-5x);
    list-style: none;
    grid-template-columns: var(--listing-card-preview-group-columns);

    @media (--screen-sm) {
      display: grid;
      grid-auto-columns: 1fr 1fr;
    }

    @media (--screen-lg) {
      grid-auto-columns: 1fr 1fr 1fr;
    }
  }

  .listingCardPreviewGroupLiHorizontal {
    width: var(--listing-card-preview-group-li-width);
    height: var(--listing-card-preview-group-li-height);
  }

  .listingCardPreviewGroupLiVertical {
    width: 100%;

    @media (--screen-md) {
      max-height: var(--list-item-max-height);
      &:hover {
        box-shadow: 0 4px 8px -2px rgb(0 0 0 / 10%);
      }
    }
  }

  .listingCardPreviewGroupFirstLi {
    order: 1;
  }

  .listingCardPreviewGroupBelowAdLi {
    order: 3;
  }

  .listingCardPreviewGroupBelowAdLi:nth-last-child(2) {
    margin-bottom: 3rem;
    @media (--screen-md) {
      margin-bottom: 0;
    }
  }

  .listingCardPreviewGroupAd {
    order: 2;
    min-height: auto;

    @media (--screen-sm) {
      order: 999;
    }
  }
}
